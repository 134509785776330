import { useQuery } from '@apollo/client';
import { Col, Row, Table, TableProps, Tooltip } from 'antd';
import Text from '../../components/Text';
import { GET_COINS_FOR_TRANSACTIONS, Query } from '../../graphql';
import { useTransferLogs } from '../../hooks';
import { colors, formatAddress, fromWei } from '../../utils';

export default function TransactionsList() {
  const { txs } = useTransferLogs();
  const { data, loading } = useQuery<Query>(GET_COINS_FOR_TRANSACTIONS);

  const getTokenName = (address: string) => {
    return (
      data?.getAllCoins?.data?.find((token) => token.address === address)
        ?.name ?? '-'
    );
  };

  const getToken = (address: string) => {
    return data?.getAllCoins?.data?.find(
      (token) => token.address?.toLowerCase() === address.toLowerCase(),
    );
  };

  const getAmount = (address: string, value: number) => {
    const token = data?.getAllCoins?.data?.find(
      (token) => token.address?.toLowerCase() === address.toLowerCase(),
    );
    console.log('token', token);
    const decimals = token?.decimals ?? 18;
    const symbol = token?.symbol ?? '';

    return `${fromWei(value, decimals)} ${symbol}`;
  };

  const columns: TableProps['columns'] = [
    {
      key: 'token',
      dataIndex: 'address',
      title: 'Token',
      render: (address) => (
        <Tooltip title={address}>{getTokenName(address)}</Tooltip>
      ),
    },
    {
      key: 'from',
      dataIndex: ['args', 'from'],
      title: 'From',
      render: (from) => formatAddress(from),
    },
    {
      key: 'amount',
      dataIndex: ['args', 'value'],
      title: 'Amount',
      render: (value, record) => (
        <Text>{getAmount(record.address, value)}</Text>
      ),
    },
    {
      key: 'transactionHash',
      dataIndex: 'transactionHash',
      title: 'TX Hash',
      render: (txHash, record) => {
        const token = getToken(record.address);

        return (
          <a
            href={token?.network?.explorerUrl + '/tx/' + txHash}
            target="_blank"
            rel="noreferrer"
          >
            <Text>{formatAddress(txHash)}</Text>
          </a>
        );
      },
    },
  ];

  return (
    <Col span={24}>
      <Row align={'middle'} justify={'start'} style={{ marginBottom: 12 }}>
        <Text size="lgStrong" style={{ color: colors.darkBg }}>
          All Transactions
        </Text>
      </Row>
      <Table
        size="large"
        columns={columns}
        dataSource={txs}
        loading={loading}
        // pagination={
        // {
        //   pageSize: 10,
        //   current: page,
        //   total: ethLogs.length,
        //   onChange: (page: number) => setPage(page),
        //   showSizeChanger: false,
        // } as PaginationProps
        // }
      />
    </Col>
  );
}
