import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { formatUnits, parseUnits } from 'viem';
import Text from '../components/Text';
import { colors } from './constants';

export const renderHtmlString = (str: string) => (
  <div dangerouslySetInnerHTML={{ __html: str }} />
);

export const copyable = (color: string = colors.link, text: string) => ({
  text,
  icon: [
    <CopyOutlined style={{ color }} />,
    <CheckOutlined style={{ color }} />,
  ],
});

export function getCookie(name: string) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1);
    }
  }
  return undefined;
}

export function openUrlInNewTab(url: string) {
  const newTab = window.open(url, '_blank', 'noopener,noreferrer');
  if (newTab) newTab.opener = null;
}

export function leftPad(number: number, targetLength: number) {
  var output = number + '';
  while (output.length < targetLength) {
    output = '0' + output;
  }
  return output;
}

export function addEllipsisToText(address: any, beforeLength: number) {
  const length = address.length;
  const start = address.slice(0, beforeLength);
  const end = address.slice(length - 3, length);
  return `${start}...${end}`;
}

export const getRemInPixels = (rem: number = 1) => {
  const temporaryElement = document.createElement('div');
  temporaryElement.style.width = `${rem}rem`;
  document.body.appendChild(temporaryElement);
  const itemWidthPixels = temporaryElement.offsetWidth;
  document.body.removeChild(temporaryElement);
  return itemWidthPixels;
};

export const maskAddressOrEmail = (input: any): string => {
  if (!input) return '';
  const MASK_CHAR = '*';
  if (input.includes('@')) {
    // For email addresses
    const [localPart, domain] = input.split('@');
    const maskedLocalPart =
      localPart.substring(0, 2) + MASK_CHAR.repeat(4) + localPart.slice(-2);
    return `${maskedLocalPart}@${domain}`;
  } else if (input.startsWith('0x')) {
    // For wallet addresses
    const maskedAddress =
      input.substring(0, 6) + MASK_CHAR.repeat(4) + input.slice(-4);
    return maskedAddress;
  }

  return input; // Return unchanged if not recognized as email or wallet address
};

export const clean = (obj: any) => {
  for (let propName in obj) {
    if (
      obj[propName] === '' ||
      obj[propName] === null ||
      obj[propName] === undefined
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const isNativeToken = (token: string | undefined | null) => {
  if (!token) return true;
  return (
    [
      '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
      '0x0000000000000000000000000000000000001010',
      '0x0000000000000000000000000000000000000000',
      '0x',
      '',
    ].indexOf(token.toLowerCase()) >= 0
  );
};

export const isBigInt = (value: any) => {
  try {
    if (value === null || value === undefined) return false;
    if (typeof value === 'bigint') return true;
    if (value.constructor.name === 'BigInt') return true;
    return BigInt(parseInt(value, 10)) !== BigInt(value);
  } catch (error) {
    return false;
  }
};

// "100000000", 8 => "1.0"
export const fromWei = (amount?: string | number | bigint, decimals = 18) => {
  if (!amount) return '0';
  const bg = BigInt(amount);
  return formatUnits(bg, decimals);
};

export const toWeiDec = (amount?: string | number, decimals = 18) => {
  if (!amount) return parseUnits('0', decimals);
  if (typeof amount === 'string') {
    return parseUnits(amount, decimals);
  }
  return parseUnits(amount.toString(), decimals);
};

export const formatAddress = (text: string) => {
  return (
    <Tooltip title={text}>
      <Text>
        {text.slice(0, 8) + '...' + text.slice(text.length - 4, text.length)}
      </Text>
    </Tooltip>
  );
};
