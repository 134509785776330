import { Menu, MenuProps } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setMenuState } from '../redux/reducers';
import { HEADER_HEIGHT } from './Header';
import { colors } from '../utils';

export const SIDEBAR_WIDTH = 268;

const Sidebar = () => {
  return (
    <Sider
      width={SIDEBAR_WIDTH}
      style={{
        marginTop: HEADER_HEIGHT,
        position: 'fixed',
        height: '100dvh',
        backgroundColor: colors.white,
        backdropFilter: 'blur(10px)',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        padding: '24px 16px',
        zIndex: 10,
      }}
    >
      <SideBarMenu />
    </Sider>
  );
};

export default Sidebar;

interface SidebarMenuProp {
  width?: string;
  onClose?: () => void;
}

type MenuItem = Required<MenuProps>['items'][number];

export const SideBarMenu = (props: SidebarMenuProp) => {
  const { width, onClose } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const selectedKey = useMemo(() => {
    const pathname = location.pathname;

    switch (pathname) {
      case '/':
      case '/networks':
      case '/network/create':
      case '/network/edit':
        return 'networks';

      case '/tokens':
      case '/token/create':
      case '/token/edit':
        return 'tokens';

      case '/categories':
      case '/category/create':
      case '/category/edit':
        return 'categories';

      case '/transactions':
        return 'transactions';

      default:
        return 'networks';
    }
  }, [location.pathname]);

  const items: MenuItem[] = [
    { key: 'networks', label: 'Networks' },
    { key: 'categories', label: 'Categories' },
    { key: 'tokens', label: 'Tokens' },
    { key: 'transactions', label: 'Transaction' },
  ];

  return (
    <Menu
      mode="inline"
      items={items}
      onClick={({ key }) => {
        navigate(`/${key}`);
        onClose?.();
      }}
      style={{ width: width ?? '' }}
      defaultSelectedKeys={['networks']}
      selectedKeys={[selectedKey]}
    />
  );
};
