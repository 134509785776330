import { Button, Card, Col, Form, Input, Row } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import Layout from 'antd/es/layout/layout';
import { Text } from '../components';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  Mutation,
  MutationLoginWithEmailArgs,
  LOGIN_WITH_EMAIL,
  TokenType,
} from '../graphql';
import { useDispatch } from 'react-redux';
import { setToken } from '../redux/reducers';
import { colors, getShadowTextStyles } from '../utils';
import logo from '../assets/headerLogo.png';
import React from 'react';

type FormItem = {
  email: string;
  password: string;
};

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sm, md, lg, xl } = useBreakpoint();
  const [login, { loading }] = useMutation<
    Mutation,
    MutationLoginWithEmailArgs
  >(LOGIN_WITH_EMAIL);

  const onFinish = (loginWithPasswordInput: FormItem) => {
    login({
      variables: {
        loginWithPasswordInput,
      },
    }).then((res) => {
      dispatch(setToken(res.data?.loginWithEmail as TokenType));
      navigate('/');
    });
  };

  return (
    <Layout
      style={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: `${colors.alternateContentBackground}11`,
      }}
    >
      <Card
        bordered={false}
        style={{
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          width: md ? (xl ? '35%' : lg ? '40%' : '50%') : sm ? '70%' : '90%',
          padding: md ? '24px' : '8px',
        }}
      >
        <Col span={24} style={{ marginBottom: 24 }}>
          <Row
            justify={'center'}
            align="middle"
            style={{ gap: 12, cursor: 'pointer' }}
            onClick={() => navigate('/')}
          >
            <img src={logo} style={{ height: md ? 54 : 48 }} />
            <Text
              strong
              style={{
                fontSize: md ? 54 : 48,
                textAlign: 'center',
                fontFamily: 'BarnacleBoy',
                color: 'white',
                ...(getShadowTextStyles({
                  borderWidth: 1,
                  shadow: 3.5,
                }) as React.CSSProperties),
                lineHeight: '32px',
              }}
            >
              MARVIN
            </Text>
          </Row>
        </Col>

        <Form
          layout={'vertical'}
          requiredMark={false}
          onFinish={onFinish}
          style={{ width: '100%' }}
        >
          <Form.Item<FormItem>
            label={'Email'}
            name="email"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Input size={'large'} />
          </Form.Item>
          <Form.Item<FormItem>
            label={'Password'}
            name="password"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Input.Password size={'large'} />
          </Form.Item>

          <Button
            block
            size={'large'}
            loading={loading}
            style={{ marginTop: '24px' }}
            type={'primary'}
            htmlType={'submit'}
          >
            Continue
          </Button>
        </Form>
      </Card>
    </Layout>
  );
}
