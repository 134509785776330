import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Token } from "../../types/tokenPage.types";

const initialState: Token[] = [];

export const AllTokenSlice = createSlice({
  name: "alltokens",
  initialState,
  reducers: {
    setAllTokens: (state, action: PayloadAction<Token[]>) => {
      state = action.payload;
      return state;
    },
    removeTokenById: (state, action: PayloadAction<string>) => {
      const tokenToRemove = action.payload;
      return state.filter((item) => item.id !== tokenToRemove);
    },
  },
});

export const { setAllTokens, removeTokenById } = AllTokenSlice.actions;

export const tokensState = (state: RootState) => state.allTokens;

export default AllTokenSlice.reducer;
