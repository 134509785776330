import {combineReducers} from 'redux';
import {activeMenu, allocation, allusers, app, ido, user, allNetworks, allTokens} from './reducers';

const createRootReducer = () =>
  combineReducers({
    user,
    app,
    ido,
    allusers,
    activeMenu,
    allocation,
    allNetworks,
    allTokens
  });

export default createRootReducer;
