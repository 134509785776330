import { LoginOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { Button, Col, Drawer, Row } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'wagmi';
import logo from '../assets/headerLogo.png';
import { SideBarMenu } from '.';
import Text from '../components/Text';
import { AllMenuState, logout } from '../redux/reducers';
import { useNavigate } from 'react-router-dom';
import { colors, getShadowTextStyles } from '../utils';

export const HEADER_HEIGHT = 64;

const Header = () => {
  const navigate = useNavigate();
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const { open } = useWeb3Modal();
  const { connector, address } = useAccount();
  const ActiveMenu = useSelector(AllMenuState);
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const onClose = () => {
    setOpenDrawer(false);
  };
  const showDrawer = () => {
    setOpenDrawer(true);
  };

  return (
    <>
      <Row
        style={{
          top: 0,
          position: 'fixed',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: colors.white,
          padding: xs ? '0px  24px' : '0px 24px',
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          // borderBottom: `4px solid ${colors.alternateContentBackground}`,
          backdropFilter: 'blur(10px)',
          height: HEADER_HEIGHT,
          zIndex: 100,
        }}
      >
        <Col>
          <Row
            align="middle"
            style={{ gap: 12, cursor: 'pointer' }}
            onClick={() => navigate('/')}
          >
            <img src={logo} style={{ height: md ? 44 : 36 }} />
            <Text
              strong
              style={{
                fontSize: md ? 32 : 28,
                textAlign: 'center',
                fontFamily: 'BarnacleBoy',
                color: 'white',
                ...(getShadowTextStyles({
                  borderWidth: 1,
                  shadow: 3.5,
                }) as React.CSSProperties),
                lineHeight: '32px',
              }}
            >
              MARVIN
            </Text>
          </Row>
        </Col>

        {md && (
          <Col style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            {(ActiveMenu === 'Unfulfilled Allocations' ||
              ActiveMenu === 'Fulfilled Allocations') && (
              <>
                {!address && (
                  <Button onClick={() => open({ view: 'Networks' })}>
                    Connect Wallet
                  </Button>
                )}
                {address && (
                  <Button onClick={() => open()}>
                    {shortenString(address)}
                  </Button>
                )}
              </>
            )}
            <Button
              type="primary"
              onClick={() => {
                dispatch(logout());
                navigate('/');
              }}
              icon={<UserOutlined />}
            >
              Logout
            </Button>
          </Col>
        )}
        {!md && (
          <Col style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '6px',
                backgroundColor: colors.alternateContentBackground,
                padding: '10px',
                cursor: 'pointer',
              }}
              onClick={showDrawer}
            >
              <MenuOutlined style={{ color: colors.bgBlue }} />
            </Col>
          </Col>
        )}
      </Row>
      <DrawerSider openDrawer={openDrawer} onClose={onClose} />
    </>
  );
};

export default Header;

interface DrawerSiderProps {
  openDrawer: boolean;
  onClose: () => void;
}

const DrawerSider = (prop: DrawerSiderProps) => {
  const { onClose, openDrawer } = prop;
  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Drawer
      placement={'left'}
      closable={false}
      onClose={onClose}
      open={openDrawer}
      key={'left'}
      // width={'258px'}
    >
      <Row
        style={{
          width: '100%',
          height: '100%',
          flexDirection: 'column',
        }}
      >
        <Row
          align="middle"
          style={{ gap: 12, cursor: 'pointer' }}
          onClick={() => {
            navigate('/');
            onClose();
          }}
        >
          <img src={logo} style={{ height: 36 }} />
          <Text
            strong
            style={{
              fontSize: 28,
              textAlign: 'center',
              fontFamily: 'BarnacleBoy',
              color: 'white',
              ...(getShadowTextStyles({
                borderWidth: 1,
                shadow: 3.5,
              }) as React.CSSProperties),
              lineHeight: '32px',
            }}
          >
            MARVIN
          </Text>
        </Row>
        <Row style={{ marginTop: '16px' }}>
          <SideBarMenu width={'100%'} onClose={onClose} />
        </Row>
        <Row style={{ marginTop: '16px' }}>
          {/*{!address && (*/}
          {/*  <Button*/}
          {/*    onClick={() => {*/}
          {/*      open({ view: 'Networks' });*/}
          {/*      onClose();*/}
          {/*    }}*/}
          {/*    style={{ width: '100%' }}*/}
          {/*  >*/}
          {/*    Connect Wallet*/}
          {/*  </Button>*/}
          {/*)}*/}
          {address && (
            <Button
              onClick={() => {
                open();
                onClose();
              }}
              style={{ width: '100%' }}
            >
              {shortenString(address)}
            </Button>
          )}
        </Row>
        <Row style={{ flexGrow: 1, alignItems: 'flex-end' }}>
          <Button
            block
            type="primary"
            onClick={() => {
              dispatch(logout());
              navigate('/');
            }}
            icon={<UserOutlined />}
          >
            Logout
          </Button>
        </Row>
      </Row>
    </Drawer>
  );
};

function shortenString(inputString: string) {
  if (inputString.length <= 7) {
    return inputString;
  }

  return (
    inputString.substring(0, 4) +
    '...' +
    inputString.substring(inputString.length - 4)
  );
}
