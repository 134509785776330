import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IdoListType } from "../../utils/types";

const initialState: IdoListType[] = [];

export const idoSlice = createSlice({
  name: "ido",
  initialState,
  reducers: {
    setido: (state, action: PayloadAction<IdoListType[]>) => {
      state = action.payload;
      return state;
    },
    removeIdoById: (state, action: PayloadAction<number>) => {
      const idToRemove = action.payload;
      return state.filter((item) => item.id !== idToRemove);
    },
  },
});

export const { setido, removeIdoById } = idoSlice.actions;

export const idoState = (state: RootState) => state.ido;

export default idoSlice.reducer;
