import { message, Modal, Row, Button, Col } from 'antd';
import Text from '../../components/Text';
import {
  Category,
  DELETE_CATEGORY,
  Mutation,
  MutationDeleteCategoryArgs,
} from '../../graphql';
import { useMutation } from '@apollo/client';

interface Props {
  open: boolean;
  handleCancel: () => void;
  refetch: () => void;
  recordToDelete: Category;
}

export default function DeleteCategoryModal(props: Props) {
  const { handleCancel, open, recordToDelete, refetch } = props;
  const [deleteCategory, { loading }] = useMutation<
    Mutation,
    MutationDeleteCategoryArgs
  >(DELETE_CATEGORY);

  const handleDelete = () => {
    deleteCategory({
      variables: { id: recordToDelete.id },
    }).then(() => {
      message.success('Category deleted!');
      refetch();
      handleCancel();
    });
  };

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      centered
      footer={null}
      title={null}
    >
      <Row>
        <Text size="md">Are you sure you want to delete this category?</Text>
      </Row>

      <Row style={{ marginTop: 12 }}>
        <Text size="sm">You can not undo this action.</Text>
      </Row>
      <Row gutter={12} style={{ marginTop: 24 }} justify={'center'}>
        <Col span={12}>
          <Button block size={'large'} onClick={handleCancel}>
            Cancel
          </Button>
        </Col>
        <Col span={12}>
          <Button
            block
            loading={loading}
            size={'large'}
            type={'primary'}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
