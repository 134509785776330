import { useEffect, useState } from 'react';
import {
  createPublicClient,
  GetLogsReturnType,
  http,
  parseAbiItem,
  webSocket,
} from 'viem';
import { base, mainnet } from 'viem/chains';

const ethClient = createPublicClient({
  chain: mainnet,
  transport: webSocket('wss://eth.drpc.org'),
});

const baseClient = createPublicClient({
  chain: base,
  transport: http(),
});

export const WALLET_ADDRESS = '0xda7211e45D824B215e60aE16100328eDCa64c27A';

const useTransferLogs = () => {
  const [ethLogs, setEthLogs] = useState<GetLogsReturnType>([]);
  const [baseLogs, setBaseLogs] = useState<GetLogsReturnType>([]);
  const [txs, setTxs] = useState<GetLogsReturnType>([]);

  const getEthLogs = async () => {
    try {
      const res = await ethClient?.getLogs({
        event: parseAbiItem(
          'event Transfer(address indexed from, address indexed to, uint256 value)',
        ),
        args: { to: [WALLET_ADDRESS] },
        fromBlock: BigInt(20000000),
        toBlock: 'latest',
      });
      setEthLogs(res);

      console.log('logs eth ', WALLET_ADDRESS, res);
    } catch (error) {
      console.error('error getting logs', error);
    }
  };

  const getBaseLogs = async () => {
    try {
      const res = await baseClient?.getLogs({
        event: parseAbiItem(
          'event Transfer(address indexed from, address indexed to, uint256 value)',
        ),
        args: { to: [WALLET_ADDRESS] },
        fromBlock: BigInt(20000000),
        toBlock: 'latest',
      });
      setBaseLogs(res);

      console.log('logs baseclient ', WALLET_ADDRESS, res);
    } catch (error) {
      console.error('error getting logs', error);
    }
  };

  useEffect(() => {
    getEthLogs();
    getBaseLogs();
  }, []);

  useEffect(() => {
    setTxs([...ethLogs, ...baseLogs]);
  }, [ethLogs, baseLogs]);

  return { ethLogs, baseLogs, txs };
};

export default useTransferLogs;
