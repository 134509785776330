import { message, Modal, Row, Button, Col } from 'antd';
import Text from '../../components/Text';
import {
  DELETE_NETWORK,
  Mutation,
  MutationRemoveNetworkArgs,
  Network,
} from '../../graphql';
import { useMutation } from '@apollo/client';

interface DeleteIdoModalProp {
  open: boolean;
  handleCancel: () => void;
  refetch: () => void;
  recordToDelete: Network;
}

export default function DeleteNetworkModal(props: DeleteIdoModalProp) {
  const { handleCancel, open, recordToDelete, refetch } = props;
  const [deleteNetwork, { loading }] = useMutation<
    Mutation,
    MutationRemoveNetworkArgs
  >(DELETE_NETWORK);

  const handleDelete = () => {
    deleteNetwork({
      variables: { id: Number(recordToDelete.id) },
    }).then(() => {
      message.success('Network deleted!');
      refetch();
      handleCancel();
    });
  };

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      centered
      footer={null}
      title={null}
    >
      <Row>
        <Text size="md">Are you sure you want to delete this network?</Text>
      </Row>

      <Row style={{ marginTop: 12 }}>
        <Text size="sm">You can not undo this action.</Text>
      </Row>
      <Row gutter={12} style={{ marginTop: 24 }} justify={'center'}>
        <Col span={12}>
          <Button block size={'large'} onClick={handleCancel}>
            Cancel
          </Button>
        </Col>
        <Col span={12}>
          <Button
            block
            loading={loading}
            size={'large'}
            type={'primary'}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
