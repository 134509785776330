import { Button, Col, Form, Input, Row, message, Card, Layout } from 'antd';
import Text from '../../components/Text';
import { useLocation, useNavigate } from 'react-router-dom';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { useMutation } from '@apollo/client';
import {
  Category,
  CREATE_CATEGORY,
  GET_CATEGORIES,
  Mutation,
  MutationCreateCategoryArgs,
  MutationUpdateCategoryArgs,
  UPDATE_CATEGORY,
} from '../../graphql';

type FormItem = {
  name: string;
  symbol: string;
  rpcUrl: string;
  chainId: number;
  explorerUrl: string;
};

export default function CategoryForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const categoryToEdit: Category = location.state?.category;
  const { sm, md, lg } = useBreakpoint();
  const [form] = Form.useForm();
  const [create, { loading: creating }] = useMutation<
    Mutation,
    MutationCreateCategoryArgs
  >(CREATE_CATEGORY);
  const [update, { loading: updating }] = useMutation<
    Mutation,
    MutationUpdateCategoryArgs
  >(UPDATE_CATEGORY);

  const onFinish = (categoryInput: FormItem) => {
    if (!!categoryToEdit?.id) {
      update({
        variables: {
          id: categoryToEdit.id,
          categoryInput,
        },
        refetchQueries: [
          { query: GET_CATEGORIES, variables: { page: 1, take: 10 } },
        ],
      }).then(() => {
        message.success('Category updated!');
        navigate(-1);
      });
    } else {
      create({
        variables: {
          categoryInput,
        },
        refetchQueries: [
          { query: GET_CATEGORIES, variables: { page: 1, take: 10 } },
        ],
      }).then(() => {
        message.success('Category created!');
        navigate(-1);
      });
    }
  };

  return (
    <Layout
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <Card
        bordered={false}
        style={{
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          width: md ? '50%' : sm ? '70%' : '99%',
        }}
      >
        <Row justify={'center'} style={{ marginBottom: 8 }}>
          <Text size={'xlStrong'}>
            {!!categoryToEdit?.id ? 'Update' : 'Add a'} Category
          </Text>
        </Row>
        <Form
          layout={'vertical'}
          requiredMark={false}
          onFinish={onFinish}
          form={form}
          initialValues={categoryToEdit}
        >
          <Col span={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: '' }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={24} style={{ marginTop: 24 }}>
            <Button
              loading={creating || updating}
              block
              type="primary"
              htmlType="submit"
              size={'large'}
            >
              {!!categoryToEdit?.id ? 'Save' : 'Submit'}
            </Button>
          </Col>
        </Form>
      </Card>
    </Layout>
  );
}
