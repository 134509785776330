import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation Login($userData: LoginInput!) {
    login(userData: $userData) {
      accessToken
      refreshToken
    }
  }
`;

export const LOGIN_WITH_EMAIL = gql`
  mutation LoginWithEmail($loginWithPasswordInput: LoginWithPasswordInput!) {
    loginWithEmail(loginWithPasswordInput: $loginWithPasswordInput) {
      accessToken
      refreshToken
    }
  }
`;

export const REGISTER = gql`
  mutation Register($registerInputData: RegisterInput!) {
    register(registerInputData: $registerInputData)
  }
`;

export const REGISTER_WITH_EMAIL = gql`
  mutation RegisterWithEmail($registerWithEmailInput: RegisterWithEmailInput!) {
    registerWithEmail(registerWithEmailInput: $registerWithEmailInput)
  }
`;

export const ADD_NETWORK = gql`
  mutation AddNetwork($networkInput: NetworkInput!) {
    addNetwork(networkInput: $networkInput) {
      id
      rpcUrl
      chainId
      name
      symbol
      explorerUrl
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const UPDATE_NETWORK = gql`
  mutation UpdateNetwork(
    $id: Float!
    $updateNetworkInput: UpdateNetworkInput!
  ) {
    updateNetwork(id: $id, updateNetworkInput: $updateNetworkInput) {
      id
      rpcUrl
      chainId
      name
      symbol
      explorerUrl
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const DELETE_NETWORK = gql`
  mutation RemoveNetwork($id: Float!) {
    removeNetwork(id: $id)
  }
`;

export const ADD_COIN = gql`
  mutation AddCoin($coinInput: CoinInput!) {
    addCoin(coinInput: $coinInput) {
      id
      name
      symbol
      address
      logo
      decimals
      isNative
      threshold
      createdAt
      updatedAt
      network {
        id
        rpcUrl
        chainId
        name
        symbol
        explorerUrl
        createdAt
        updatedAt
        deletedAt
      }
      category {
        id
        name
      }
    }
  }
`;

export const UPDATE_COIN = gql`
  mutation UpdateCoin($id: Float!, $updateCoinInput: UpdateCoinInput!) {
    updateCoin(id: $id, updateCoinInput: $updateCoinInput) {
      id
      name
      symbol
      address
      logo
      decimals
      isNative
      threshold
      createdAt
      updatedAt
      network {
        id
        rpcUrl
        chainId
        name
        symbol
        explorerUrl
        createdAt
        updatedAt
        deletedAt
      }
      category {
        id
        name
      }
    }
  }
`;

export const DELETE_COIN = gql`
  mutation RemoveCoin($id: Float!) {
    removeCoin(id: $id)
  }
`;

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($categoryInput: CategoryInput!) {
    createCategory(categoryInput: $categoryInput) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($id: String!, $categoryInput: CategoryInput!) {
    updateCategory(id: $id, categoryInput: $categoryInput) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($id: String!) {
    deleteCategory(id: $id)
  }
`;
