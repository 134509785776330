import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {AllocationType} from '../../utils/types';
import {RootState} from '../store';

const initialState: AllocationType[] = [];

export const allocationSlice = createSlice({
  name: 'allocation',
  initialState,
  reducers: {
    setAllocation: (state, action: PayloadAction<AllocationType[]>) => {
      state = action.payload;
      return state;
    },
    updateAllocation: (state, action: PayloadAction<AllocationType>) => {
      const index = state.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        state[index] = action.payload;
      }
      return state;
    },
  },
});

export const {setAllocation, updateAllocation} = allocationSlice.actions;

export const selectAllocation = (state: RootState) => state.allocation;

export default allocationSlice.reducer;
