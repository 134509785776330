import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { AllUsers } from "../../utils/types";
import { Network } from "../../types/networkPage.types";

const initialState: Network[] = [];

export const AllNetworkSlice = createSlice({
  name: "allnetworks",
  initialState,
  reducers: {
    setAllNetworks: (state, action: PayloadAction<Network[]>) => {
      state = action.payload;
      return state;
    },
    removeNetworkById: (state, action: PayloadAction<number>) => {
      const networkToRemove = action.payload;
      return state.filter((item) => item.id !== networkToRemove);
    },
  },
});

export const { setAllNetworks, removeNetworkById } = AllNetworkSlice.actions;

export const networksState = (state: RootState) => state.allNetworks;

export default AllNetworkSlice.reducer;
