import {
  ApolloClient,
  ApolloLink,
  DocumentNode,
  from,
  HttpLink,
  InMemoryCache,
  OperationVariables,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { message as antdMessage } from 'antd';
import { setContext } from 'apollo-link-context';
import { refreshTokenReq } from './refreshToken';

const SERVER_URL_GQL = 'https://gql.marvinonbase.com/graphql';
// const SERVER_URL_GQL = 'http://localhost:3099/graphql';

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response, forward }) => {
    if (graphQLErrors || networkError || response) {
      operation.query.definitions.forEach((definition) => {
        const op = 'operation' in definition ? definition.operation : '';
        console.log(
          `[Operation OCL] apollo ${op} ${operation.operationName || ''}`,
        );
      });
    }

    if (response) {
      console.log(`[Operation Result OCL] ${JSON.stringify(response)}`);
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(async ({ message, locations, path }) => {
        const locationsStr = JSON.stringify(locations);
        if (message !== 'jwt malformed') antdMessage.error(message);

        console.warn(
          `[GraphQL Error OCL] Message: "${message}", Locations: ${locationsStr}, Path: "${path}"`,
        );
        if (message === 'jwt expired') {
          //refresh token
          const refreshAccessToken = localStorage.getItem('refreshToken');
          if (!refreshAccessToken)
            return console.error('refreshAccessToken not found');
          const res = await refreshTokenReq(refreshAccessToken);
          if (!res) return console.error('refreshTokenReq error');
          localStorage.setItem('token', res.accessToken);
          localStorage.setItem('refreshToken', res.refreshToken);
          operation.setContext(({ headers = {} }: Record<string, any>) => ({
            headers: {
              ...headers,
              Authorization: `Bearer ${res.accessToken}`, // should the same with Auth link
            },
          }));
          return forward(operation);
        }
      });
    }

    if (networkError) {
      console.error(`[Network Error OCL] "${networkError}"`);
    }
  },
);

const httpLink = new HttpLink({
  uri: SERVER_URL_GQL,
});

const authMiddleware = setContext((operation) => {
  const accessToken = localStorage.getItem('token');
  // console.log(
  //   `[Operation OCL operationName] ${operation.operationName || ''}`,
  // );
  // console.log(
  //   `[Operation OCL variables] ${JSON.stringify(operation.variables)}`,
  // );
  // console.log(`[Operation OCL query] ${JSON.stringify(operation.query)}`);
  // console.log('accessToken authMiddleware', accessToken);

  return {
    // Make sure to actually set the headers here
    headers: {
      authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  };
});

const client = new ApolloClient({
  link: from([errorLink, authMiddleware as unknown as ApolloLink, httpLink]),
  cache: new InMemoryCache(),
  // connectToDevTools: true,
});

export const fetchRequestClient = async ({
  query,
  variables,
}: {
  query: DocumentNode | string;
  variables: OperationVariables;
}) => {
  try {
    const res = await fetch(SERVER_URL_GQL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });
    return await res.json();
  } catch (err) {
    console.log('request failed with', err);
    return await Promise.reject(err);
  }
};

export default client;
