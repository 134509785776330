import { TableProps, Row, Table, Button, Col, PaginationProps } from 'antd';
import { useState } from 'react';
import Text from '../../components/Text';
import { DeleteCategoryModal } from '.';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  Category,
  GET_CATEGORIES,
  Query,
  QueryGetCategoriesArgs,
} from '../../graphql';
import { colors } from '../../utils';

export default function CategoryList() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [recordToDelete, setRecordToDelete] = useState<Category>();
  const { data, loading, refetch } = useQuery<Query, QueryGetCategoriesArgs>(
    GET_CATEGORIES,
    {
      variables: {
        page,
        take: 10,
      },
    },
  );

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleEdit = (category: Category) => {
    navigate('/category/edit', { state: { category } });
  };

  const handleDelete = (record: Category) => {
    setRecordToDelete(record);
    setIsModalOpen(true);
  };

  const columns: TableProps<Category>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record: Category) => (
        <Row style={{ gap: 12 }}>
          <Button
            size="small"
            onClick={() => {
              handleEdit(record);
            }}
          >
            Edit
          </Button>
          <Button
            size="small"
            onClick={() => {
              handleDelete(record);
            }}
          >
            Delete
          </Button>
        </Row>
      ),
    },
  ];

  return (
    <Col span={24}>
      <Row
        align={'middle'}
        justify={'space-between'}
        style={{ marginBottom: 12 }}
      >
        <Col>
          <Text size="lgStrong" style={{ color: colors.darkBg }}>
            All Categories
          </Text>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              navigate('/category/create');
            }}
          >
            Add category
          </Button>
        </Col>
      </Row>
      <Table
        size="large"
        columns={columns}
        dataSource={data?.getCategories?.data}
        loading={loading}
        pagination={
          {
            pageSize: 10,
            current: page,
            total: data?.getCategories?.count,
            onChange: (page: number) => setPage(page),
            showSizeChanger: false,
          } as PaginationProps
        }
      />
      {recordToDelete && (
        <DeleteCategoryModal
          refetch={() => {
            if (page !== 1) setPage(1);
            refetch();
          }}
          handleCancel={handleCancel}
          open={isModalOpen}
          recordToDelete={recordToDelete}
        />
      )}
    </Col>
  );
}
