import { ApolloProvider } from '@apollo/client';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import { ConfigProvider } from 'antd';
import { WagmiProvider } from 'wagmi';
import App from './App';
import { GqClient } from './graphql';
import { breakPoints, colors, projectId, wagmiConfig } from './utils';

createWeb3Modal({
  wagmiConfig: wagmiConfig,
  projectId: projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
  themeMode: 'light',
  themeVariables: {
    '--w3m-color-mix': '#006AF6',
    '--w3m-color-mix-strength': 35,
    '--w3m-font-family':
      '"Avenir Next LT Pro bold", "Avenir Next LT Pro", "Helvetica Neue",sans-serif',
    '--w3m-accent': 'black',
  },
});

const ThemedApp = () => {
  const { screenXS, screenSM, screenMD, screenLG, screenXL, screenXXL } =
    breakPoints;
  return (
    <WagmiProvider config={wagmiConfig}>
      <ApolloProvider client={GqClient}>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: 'Poppins',
              screenXS,
              screenXSMin: screenXS,
              screenXSMax: screenSM - 1,
              screenSM,
              screenSMMin: screenSM,
              screenSMMax: screenMD - 1,
              screenMD,
              screenMDMin: screenMD,
              screenMDMax: screenLG - 1,
              screenLG,
              screenLGMin: screenLG,
              screenLGMax: screenXL - 1,
              screenXL,
              screenXLMin: screenXL,
              screenXLMax: screenXXL - 1,
              screenXXL,
              screenXXLMin: screenXXL,
            },
            components: {
              Empty: {
                colorText: colors.bgBlue,
                colorTextDescription: colors.bgBlue,
              },
              Menu: {
                itemBg: 'transparent',
                itemSelectedColor: '#F2F2F5',
                itemSelectedBg: colors.bgBlue,
                colorText: colors.darkBg,
                fontSize: 14,
                itemMarginBlock: 8,
                colorIcon: colors.bgBlue,
              },
              Button: {
                paddingBlockLG: 12,
                controlHeightLG: 40,
                fontSize: 14,
                colorText: 'black',
                fontWeight: 400,
                borderRadius: 8,
                borderRadiusLG: 8,
                ///////Default Button/////////
                defaultBg: 'transparent',
                defaultBorderColor: colors.bgBlue,
                defaultColor: colors.bgBlue,
                defaultHoverBg: 'transparent',
                defaultHoverColor: colors.bgBlue,
                colorPrimaryActive: colors.bgBlue,

                ////Primary Button//////
                colorPrimary: colors.bgBlue,
                colorPrimaryHover: colors.bgBlue,
                primaryColor: '#FFFFFF',
                colorTextLightSolid: '#FFFFFF',
                // colorTextLightSolid: '#000000',

                ////////disable////////
                colorTextDisabled: '#242426',
                colorBgContainerDisabled: 'rgba(255, 255, 255, 0.25)',
                borderColorDisabled: 'rgba(255, 255, 255, 0.25)',
              },
              Checkbox: {
                colorPrimary: colors.bgBlue,
                colorPrimaryHover: colors.bgBlue,
                colorText: 'black',
                colorBgContainer: 'white',
                colorBorder: colors.bgBlue,
              },
              Input: {
                // activeBg: 'rgb(56,56,56)',
                // activeBorderColor: 'rgb(56,56,56)',
                // hoverBg: 'rgb(56,56,56)',
                // hoverBorderColor: 'rgb(56,56,56)',
                // colorBgContainer: 'rgb(56,56,56)',
                // colorBorder: 'rgb(56,56,56)',
                // colorIcon: '#767676',
                // colorText: '#F0F0F0',
                // colorTextPlaceholder: 'rgba(0, 0, 0, 0.25)',
              },
              Select: {
                // selectorBg: 'rgb(56,56,56)',
                // colorBorder: 'rgb(56,56,56)',
                // optionSelectedColor: 'white',
                // optionSelectedBg: 'rgb(69,69,69)',
                // colorBgElevated: 'rgb(56,56,56)',
                // colorText: '#F0F0F0',
                // colorPrimaryHover: 'none',
                // colorTextPlaceholder: 'white',
                // colorPrimary: 'rgb(56,56,56)',
              },
              // Drawer: {
              //   colorBgElevated: "#141414",
              //   padding: 0,
              //   paddingLG: 0,
              // },
              Table: {
                colorBgContainer: colors.white,
                headerSplitColor: colors.bgBlue,
                headerColor: colors.white,
                headerBg: colors.bgBlue,
                headerBorderRadius: 12,
                borderColor: colors.alternateContentBackground,
                rowExpandedBg: colors.alternateContentBackground,
                rowHoverBg: `${colors.lightBlue}22`,
                colorText: colors.darkBg,
                rowSelectedBg: 'transparent',
                rowSelectedHoverBg: 'transparent',
              },
              Pagination: {
                itemActiveBg: colors.white,
                colorText: colors.darkBlue,
                colorPrimary: colors.darkBlue,
                colorPrimaryHover: colors.bgBlue,
                colorBgTextHover: colors.white,
              },
              DatePicker: {
                colorBgContainer: 'rgba(0, 0, 0, 0.06)',
                colorBorder: 'rgba(0, 0, 0, 0.06)',
                colorPrimary: 'none',
                colorPrimaryHover: 'none',
                colorTextPlaceholder: 'rgba(0, 0, 0, 0.25)',
                colorText: 'black',
                colorBgElevated: 'white',
                colorTextHeading: 'black',
                colorIcon: '(0, 0, 0, 0.25)',
                colorTextLightSolid: 'rgba(255, 255, 255, 0.25)',
                colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
                cellHoverWithRangeBg: 'red',
              },
              // Upload: {
              //   actionsColor: "rgba(255, 255, 255, 0.25)",
              //   colorText: "rgba(255, 255, 255, 0.25)",
              // },
              Form: {
                itemMarginBottom: 8,
                labelFontSize: 12,
                verticalLabelPadding: 2,
                labelColor: colors.darkBg,
              },
              Spin: {
                colorPrimary: colors.darkBlue,
              },
              Card: {
                borderRadiusLG: 12,
                colorBgContainer: colors.white,
              },
              Modal: {
                colorBgElevated: colors.white,
                colorIcon: colors.bgBlue,
              },
            },
          }}
        >
          <App />
        </ConfigProvider>
      </ApolloProvider>
    </WagmiProvider>
  );
};

export default ThemedApp;
