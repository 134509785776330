import { gql } from '@apollo/client';

export const GET_USER = gql`
  query LoggedInUser {
    loggedInUser {
      id
      email
      walletAddress
      role
      createdAt
      updatedAt
    }
  }
`;

export const GET_NETWORKS = gql`
  query GetAllNetworks($page: Float, $take: Float) {
    getAllNetworks(page: $page, take: $take) {
      data {
        id
        rpcUrl
        chainId
        name
        symbol
        explorerUrl
        createdAt
        updatedAt
        deletedAt
      }
      count
    }
  }
`;

export const GET_CATEGORIES = gql`
  query GetCategories($page: Float, $take: Float) {
    getCategories(page: $page, take: $take) {
      data {
        id
        name
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const GET_NETWORK_BY_ID = gql`
  query GetNetworkById($id: Float!) {
    getNetworkById(id: $id) {
      id
      rpcUrl
      chainId
      name
      symbol
      explorerUrl
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const GET_COINS = gql`
  query GetAllCoins(
    $page: Float
    $take: Float
    $search: String
    $category: String
  ) {
    getAllCoins(
      page: $page
      take: $take
      search: $search
      category: $category
    ) {
      data {
        id
        name
        symbol
        address
        logo
        decimals
        isNative
        createdAt
        updatedAt
        threshold
        network {
          id
          rpcUrl
          chainId
          name
          symbol
          explorerUrl
          createdAt
          updatedAt
          deletedAt
        }
        category {
          id
          name
        }
      }
      count
    }
  }
`;

export const GET_COINS_FOR_TRANSACTIONS = gql`
  query GetAllCoins {
    getAllCoins {
      data {
        id
        name
        address
        decimals
        symbol
        network {
          id
          rpcUrl
          chainId
          name
          symbol
          explorerUrl
          createdAt
          updatedAt
          deletedAt
        }
      }
      count
    }
  }
`;

export const GET_COIN_BY_ID = gql`
  query GetCoinById($id: Float!) {
    getCoinById(id: $id) {
      id
      name
      symbol
      address
      logo
      decimals
      isNative
      threshold
      createdAt
      updatedAt
      network {
        id
        rpcUrl
        chainId
        name
        symbol
        explorerUrl
        createdAt
        updatedAt
        deletedAt
      }
      category {
        id
        name
      }
    }
  }
`;
