import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';
import { base, bsc, mainnet } from 'wagmi/chains';

export const projectId = 'eeda782a6ba14353474f0333075fa662';

export const metadata = {
  name: 'Marvin',
  description: 'Marvin buy and sell latest Tokens',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
};

const chains = [base, mainnet, bsc] as const;

export const wagmiConfig = defaultWagmiConfig({
  chains, // required
  projectId, // required
  metadata, // required
  enableWalletConnect: true, // Optional - true by default
  enableInjected: true, // Optional - true by default
  enableEIP6963: true, // Optional - true by default
  enableCoinbase: true, // Optional - true by default
});
