import { Layout, Row, Spin } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.scss';
import { Header, HEADER_HEIGHT, Sidebar, SIDEBAR_WIDTH } from './components';
import {
  Login,
  NetworkForm,
  NetworkList,
  TokenList,
  TokenForm,
  CategoryList,
  CategoryForm,
  TransactionsList,
} from './pages';
import { useQuery } from '@apollo/client';
import { GET_USER, LoggedInUser, Query } from './graphql';
import { useEffect } from 'react';
import { colors } from './utils';
import { useSelector } from 'react-redux';
import { userState } from './redux/reducers';

function App() {
  const { token } = useSelector(userState);

  return (
    <Router>
      <Layout
        style={{
          minHeight: '100vh',
          backgroundColor: '#e3e3e333',
          backgroundBlendMode: 'soft-light',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {token ? <LoggedInRoutes /> : <NotLoggedInRoutes />}
      </Layout>
    </Router>
  );
}

export default App;

const LoggedInRoutes = () => {
  const { md } = useBreakpoint();
  const { data, loading } = useQuery<Query>(GET_USER);

  useEffect(() => {
    if (data?.loggedInUser) {
      LoggedInUser(data.loggedInUser);
    }
  }, [data?.loggedInUser]);

  return (
    <Layout
      style={{
        minHeight: '100vh',
        padding: '0px',
        backgroundColor: 'transparent',
      }}
    >
      <Header />
      {md && <Sidebar />}
      <Layout.Content
        style={{
          backgroundColor: `${colors.alternateContentBackground}11`,
          marginLeft: md ? SIDEBAR_WIDTH : 'unset',
          marginTop: HEADER_HEIGHT,
          minHeight: `calc('100vh' - ${HEADER_HEIGHT})`,
          padding: 24,
        }}
      >
        {loading ? (
          <Row justify={'center'} style={{ padding: '6em' }}>
            <Spin />
          </Row>
        ) : (
          <Routes>
            <Route path="/networks" element={<NetworkList />} />
            <Route path="/network/create" element={<NetworkForm />} />
            <Route path="/network/edit" element={<NetworkForm />} />

            <Route path="/tokens" element={<TokenList />} />
            <Route path="/token/create" element={<TokenForm />} />
            <Route path="/token/edit" element={<TokenForm />} />

            <Route path="/categories" element={<CategoryList />} />
            <Route path="/category/create" element={<CategoryForm />} />
            <Route path="/category/edit" element={<CategoryForm />} />

            <Route path="/transactions" element={<TransactionsList />} />

            <Route path="*" element={<NetworkList />} />
          </Routes>
        )}
      </Layout.Content>
    </Layout>
  );
};

const NotLoggedInRoutes = () => {
  return (
    <Layout
      style={{
        height: '100vh',
        padding: '0px',
        backgroundColor: 'transparent',
      }}
    >
      <Routes>
        <Route path="*" element={<Login />} />
      </Routes>
    </Layout>
  );
};
