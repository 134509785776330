import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Layout,
  message,
  Card,
} from 'antd';
import Text from '../../components/Text';
import { useLocation, useNavigate } from 'react-router-dom';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { useMutation, useQuery } from '@apollo/client';
import {
  ADD_COIN,
  Coin,
  Mutation,
  MutationAddCoinArgs,
  MutationUpdateCoinArgs,
  Query,
  QueryGetAllNetworksArgs,
  GET_NETWORKS,
  GET_COINS,
  UPDATE_COIN,
  QueryGetCategoriesArgs,
  GET_CATEGORIES,
} from '../../graphql';
import { isNativeToken } from '../../utils';

type FormItem = {
  name: string;
  symbol: string;
  isNative: boolean;
  address: string;
  decimals: number;
  threshold: string;
  logo: string;
  network: number;
  categoryId: string;
};

const TokenForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const tokenToEdit: Coin = location.state?.coin;
  const { sm, md, lg } = useBreakpoint();
  const [form] = Form.useForm();
  const { Option } = Select;
  const [create, { loading: creating }] = useMutation<
    Mutation,
    MutationAddCoinArgs
  >(ADD_COIN);
  const [update, { loading: updating }] = useMutation<
    Mutation,
    MutationUpdateCoinArgs
  >(UPDATE_COIN);
  const { data: networks, loading: networksLoading } = useQuery<
    Query,
    QueryGetAllNetworksArgs
  >(GET_NETWORKS, {
    variables: { page: 1, take: 50 },
    fetchPolicy: 'network-only',
  });
  const { data: categories, loading: categoriesLoading } = useQuery<
    Query,
    QueryGetCategoriesArgs
  >(GET_CATEGORIES, {
    variables: { page: 1, take: 50 },
    fetchPolicy: 'network-only',
  });

  const onFinish = (values: FormItem) => {
    if (!!tokenToEdit?.id) {
      update({
        variables: {
          id: Number(tokenToEdit.id),
          updateCoinInput: {
            ...values,
            isNative: isNativeToken(values.address),
            decimals: Number(values.decimals),
            network: Number(values.network),
          },
        },
        refetchQueries: [
          { query: GET_COINS, variables: { page: 1, take: 10 } },
        ],
      }).then(() => {
        message.success('Token updated!');
        navigate(-1);
      });
    } else {
      create({
        variables: {
          coinInput: {
            ...values,
            isNative: isNativeToken(values.address),
            decimals: Number(values.decimals),
            network: Number(values.network),
          },
        },
        refetchQueries: [
          { query: GET_COINS, variables: { page: 1, take: 10 } },
        ],
      }).then(() => {
        message.success('Token created!');
        navigate(-1);
      });
    }
  };

  return (
    <Layout
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <Card
        bordered={false}
        style={{
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          width: md ? '50%' : sm ? '70%' : '99%',
        }}
      >
        <Row justify={'center'} style={{ marginBottom: 8 }}>
          <Text size={'xlStrong'}>
            {!!tokenToEdit?.id ? 'Update' : 'Add a'} Token
          </Text>
        </Row>
        <Form
          layout={'vertical'}
          requiredMark={false}
          onFinish={onFinish}
          form={form}
          initialValues={{
            ...tokenToEdit,
            network: tokenToEdit?.network?.id,
            categoryId: tokenToEdit?.category?.id,
          }}
        >
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                label="Address"
                name="address"
                rules={[{ required: true, message: '' }]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: '' }]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Symbol"
                name="symbol"
                rules={[{ required: true, message: '' }]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Decimals"
                name="decimals"
                rules={[{ required: true, message: '' }]}
              >
                <Input type={'number'} size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Threshold"
                name="threshold"
                rules={[{ required: true, message: '' }]}
              >
                <Input type={'number'} size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Network"
                name="network"
                rules={[{ required: true, message: '' }]}
              >
                <Select size={'large'} loading={networksLoading}>
                  {networks?.getAllNetworks?.data?.map((network) => (
                    <Option key={network.id} value={network.id}>
                      {network.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Category"
                name="categoryId"
                rules={[{ required: true, message: '' }]}
              >
                <Select size={'large'} loading={categoriesLoading}>
                  {categories?.getCategories?.data?.map((category) => (
                    <Option key={category.id} value={category.id}>
                      {category.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Logo URL"
                name="logo"
                rules={[{ required: true, message: '' }]}
              >
                <Input size={'large'} />
              </Form.Item>
            </Col>
          </Row>
          <Col span={24} style={{ marginTop: 24 }}>
            <Button
              loading={creating || updating}
              block
              type="primary"
              htmlType="submit"
              size={'large'}
            >
              {!!tokenToEdit?.id ? 'Save' : 'Submit'}
            </Button>
          </Col>
        </Form>
      </Card>
    </Layout>
  );
};

export default TokenForm;
