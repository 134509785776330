import {
  TableProps,
  Row,
  Table,
  Button,
  Col,
  Input,
  Select,
  PaginationProps,
} from 'antd';
import { useState } from 'react';
import Text from '../../components/Text';
import { useNavigate } from 'react-router-dom';
import { DeleteTokenModal } from '.';
import { useQuery } from '@apollo/client';
import {
  Coin,
  GET_CATEGORIES,
  GET_COINS,
  Network,
  Query,
  QueryGetAllCoinsArgs,
  QueryGetCategoriesArgs,
} from '../../graphql';
import { colors } from '../../utils';
import { debounce } from 'lodash';

const TokenListView = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [category, setCategory] = useState<string>();
  const [recordToDelete, setRecordToDelete] = useState<Coin>();
  const { data, loading, refetch } = useQuery<Query, QueryGetAllCoinsArgs>(
    GET_COINS,
    {
      variables: {
        page,
        take: 10,
        search,
        category,
      },
    },
  );
  const { data: categories, loading: categoriesLoading } = useQuery<
    Query,
    QueryGetCategoriesArgs
  >(GET_CATEGORIES, {
    variables: { page: 1, take: 50 },
    fetchPolicy: 'network-only',
  });

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleEdit = (coin: Coin) => {
    navigate('/token/edit', { state: { coin } });
  };

  const handleDelete = (record: Coin) => {
    setRecordToDelete(record);
    setIsModalOpen(true);
  };

  const columns: TableProps<Coin>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (item) => item?.name ?? '-',
      responsive: ['md'],
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      responsive: ['xl'],
    },
    {
      title: 'Network',
      dataIndex: 'network',
      key: 'network',
      render: (record: Network) => <span>{record?.name}</span>,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record: Coin) => (
        <Row style={{ gap: 12 }}>
          <Button
            size="small"
            onClick={() => {
              handleEdit(record);
            }}
          >
            Edit
          </Button>
          <Button
            size="small"
            onClick={() => {
              handleDelete(record);
            }}
          >
            Delete
          </Button>
        </Row>
      ),
    },
  ];

  const debouncedSearch = debounce((searchTerm) => {
    setSearch(searchTerm);
  }, 500);

  return (
    <Col span={24}>
      <Row
        align={'middle'}
        justify={'space-between'}
        style={{ marginBottom: 12 }}
      >
        <Col>
          <Text size="lgStrong" style={{ color: colors.darkBg }}>
            All Tokens
          </Text>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              navigate('/token/create');
            }}
          >
            Add token
          </Button>
        </Col>
      </Row>
      <Row
        align={'middle'}
        justify={'start'}
        style={{ marginBottom: 12 }}
        gutter={[8, 8]}
      >
        <Col xs={24} sm={12} md={8} xl={6}>
          <Input
            value={searchValue}
            onChange={(e) => {
              if (page !== 1) setPage(1);
              setSearchValue(e.target.value);
              debouncedSearch(e.target.value);
            }}
            placeholder={'Search by name, symbol or address'}
            style={{ width: '100%' }}
          />
        </Col>
        <Col xs={24} sm={12} md={8} xl={6}>
          <Select
            allowClear
            value={category}
            onChange={setCategory}
            placeholder={'Search by category'}
            style={{ width: '100%' }}
            loading={categoriesLoading}
          >
            {categories?.getCategories?.data?.map((category) => (
              <Select.Option key={category.id} value={category.id}>
                {category.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Table
        size="large"
        columns={columns}
        dataSource={data?.getAllCoins?.data}
        loading={loading}
        pagination={
          {
            pageSize: 10,
            current: page,
            total: data?.getAllCoins?.count,
            onChange: (page: number) => setPage(page),
            showSizeChanger: false,
          } as PaginationProps
        }
      />
      {recordToDelete && (
        <DeleteTokenModal
          refetch={() => {
            if (page !== 1) setPage(1);
            refetch();
          }}
          handleCancel={handleCancel}
          open={isModalOpen}
          recordToDelete={recordToDelete}
        />
      )}
    </Col>
  );
};

export default TokenListView;
