export const minMarginY = 32; // top bar height
export const minMarginX = 100;
export const appBarHeight = 24; // app window's title bar height

export type Color = keyof typeof colors;

export const colors = {
  bgBlue: '#388EFF',
  lightBlue: '#B0D2FF',
  darkBlue: '#006AF6',
  darkBg: '#1A2930',
  bg: 'rgba(32,80,144,0.7)',
  primary: '#FFCB3A',
  secondary: '#354DFE',
  secondary90: '#354DFEEE',
  gray: '#2D2D2D',
  white50: 'rgba(255, 255, 255, 0.50)',
  white20: 'rgba(255, 255, 255, 0.20)',
  white15: 'rgba(255, 255, 255, 0.15)',
  white10: 'rgba(255, 255, 255, 0.10)',
  white5: 'rgba(255, 255, 255, 0.05)',
  black15: 'rgba(0, 0, 0, 0.15)',

  transparent: 'transparent',
  white: '#fff',
  black: '#000',
  alternateContentBackground: '#F4F5F5',
  controlAccent: '#007AFF',
  text: '#000',
  secondaryLight: '#00000080',
  headerLight: '#000000D9',
  textRegular: 'rgb(42,42,42)',
  textLight: 'rgba(0, 0, 0, 0.80)',
  headerText: 'rgb(42,42,42)',
  label: 'rgb(42,42,42)',
  controlText: 'rgb(42,42,42)',
  textDisabled: 'rgba(0, 0, 0, 0.25)',
  findHighlighting: '#FF0',
  grid: '#E6E6E6',
  focusIndicator: 'rgba(0, 103, 244, 0.25)',
  link: '#0068DA',
  linkLight: '#0068DA',
  linkDisabled: '#B3D7FF',
  placeholder: 'rgba(0, 0, 0, 0.25)',
  quaternary: 'rgba(0, 0, 0, 0.10)',
  separator: 'rgba(0, 0, 0, 0.10)',
  tertiaryLabel: 'rgba(0, 0, 0, 0.26)',
  selectedContentBackground: '#0063E1',
  selectedContentControl: '#B3D7FF',
  selectedTextBackground: '#B3D7FF',
  selectedText: '#000',
  systemBlue: '#007AFF',
  systemBrown: '#A2845E',
  systemGray: '#8E8E93',
  systemGreen: '#34C759',
  systemIndigo: '#5856D6',
  systemOrange: '#FF9500',
  systemPink: '#FF2D55',
  systemPurple: '#AF52DE',
  systemRed: '#FF3B30',
  systemTeal: '#55BEF0',
  systemYellow: '#FFCC00',
  underPageBackground: 'rgba(150, 150, 150, 0.90)',
  unemphasizedSelectedContentBackground: '#DCDCDC',
  unemphasizedSelectedTextBackground: '#DCDCDC',
  windowBackground: '#ECECEC',
};

export const breakPoints = {
  screenXS: 395,
  screenSM: 599,
  screenMD: 959,
  screenLG: 1279,
  screenXL: 1439,
  screenXXL: 1919,
};

export const getShadowDivStyles = ({
  borderWidth = 2,
  shadow = 4,
  borderColor = 'black',
  shadowColor = 'black',
}: {
  borderWidth?: number;
  shadow?: number;
  borderColor?: string;
  shadowColor?: string;
}): React.CSSProperties => ({
  boxShadow: `0px ${shadow}px 0px 0px ${shadowColor}`,
  border: `${borderWidth}px solid ${borderColor}`,
});

export const getShadowTextStyles = ({
  borderWidth = 2,
  shadow = 4,
  borderColor = 'black',
  shadowColor = 'black',
}: {
  borderWidth?: number;
  shadow?: number;
  borderColor?: string;
  shadowColor?: string;
}): {
  letterSpacing: string;
  lineHeight: string;
  webkitTextStroke: string;
  textShadow: string;
  textTransform: string;
} => ({
  textTransform: 'uppercase',
  letterSpacing: '-2.2px',
  // @ts-ignore
  webkitTextStroke: `${borderWidth}px ${borderColor}`,
  textShadow: `${shadowColor} 0px ${shadow}px 0px`,
  lineHeight: '110%',
});
