import { Button, Col, Form, Input, Layout, Row, message, Card } from 'antd';
import Text from '../../components/Text';
import { useLocation, useNavigate } from 'react-router-dom';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { useMutation } from '@apollo/client';
import {
  ADD_NETWORK,
  GET_NETWORKS,
  Mutation,
  MutationAddNetworkArgs,
  MutationUpdateNetworkArgs,
  Network,
  UPDATE_NETWORK,
} from '../../graphql';

type FormItem = {
  name: string;
  symbol: string;
  rpcUrl: string;
  chainId: number;
  explorerUrl: string;
};

const NetworkForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const networkToEdit: Network = location.state?.network;
  const { sm, md, lg } = useBreakpoint();
  const [form] = Form.useForm();
  const [create, { loading: creating }] = useMutation<
    Mutation,
    MutationAddNetworkArgs
  >(ADD_NETWORK);
  const [update, { loading: updating }] = useMutation<
    Mutation,
    MutationUpdateNetworkArgs
  >(UPDATE_NETWORK);

  const onFinish = (values: FormItem) => {
    if (!!networkToEdit?.id) {
      update({
        variables: {
          id: Number(networkToEdit.id),
          updateNetworkInput: {
            ...values,
            chainId: Number(values.chainId),
          },
        },
        refetchQueries: [
          { query: GET_NETWORKS, variables: { page: 1, take: 10 } },
        ],
      }).then(() => {
        message.success('Network updated!');
        navigate(-1);
      });
    } else {
      create({
        variables: {
          networkInput: {
            ...values,
            chainId: Number(values.chainId),
          },
        },
        refetchQueries: [
          { query: GET_NETWORKS, variables: { page: 1, take: 10 } },
        ],
      }).then(() => {
        message.success('Network created!');
        navigate(-1);
      });
    }
  };

  return (
    <Layout
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <Card
        bordered={false}
        style={{
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          width: md ? '50%' : sm ? '70%' : '99%',
        }}
      >
        <Row justify={'center'} style={{ marginBottom: 8 }}>
          <Text size={'xlStrong'}>
            {!!networkToEdit?.id ? 'Update' : 'Add a'} Network
          </Text>
        </Row>
        <Form
          layout={'vertical'}
          requiredMark={false}
          onFinish={onFinish}
          form={form}
          initialValues={networkToEdit}
        >
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: '' }]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Chain ID"
                name="chainId"
                rules={[{ required: true, message: '' }]}
              >
                <Input type="number" size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Symbol"
                name="symbol"
                rules={[{ required: true, message: '' }]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="RPC Url"
                name="rpcUrl"
                rules={[{ required: true, message: '' }]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Explorer URL"
                name="explorerUrl"
                rules={[{ required: true, message: '' }]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Col span={24} style={{ marginTop: 24 }}>
            <Button
              loading={creating || updating}
              block
              type="primary"
              htmlType="submit"
              size={'large'}
            >
              {!!networkToEdit?.id ? 'Save' : 'Submit'}
            </Button>
          </Col>
        </Form>
      </Card>
    </Layout>
  );
};

export default NetworkForm;
